import classNames from 'classnames';
import { createElement, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  color?: 'error' | 'inverse' | 'secondary' | 'primary';
  component?: 'div' | 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4';
  id?: string;
  itemProp?: string;
  mb?: '8' | '12' | '16' | '24' | '32' | '48' | '64';
  variant:
    | 'heading100'
    | 'heading200'
    | 'heading300'
    | 'heading400'
    | 'heading500'
    | 'heading600'
    | 'heading700'
    | 'paragraph100'
    | 'paragraph200'
    | 'paragraph300'
    | 'text100'
    | 'text100bold'
    | 'text200'
    | 'text200bold'
    | 'text300'
    | 'text300bold';
};

// loosely based upon https://mui.com/components/typography/
export default function Typography({
  children,
  className,
  color = 'primary',
  component = 'span',
  id,
  mb,
  variant,
  itemProp
}: PropsWithChildren<Props>) {
  const variantClassName = styles[variant!];

  // because of css-loader exportLocalsConvention camelCase the X is uppercase
  // Maybe we should see what library/logic css-loader uses for camel-casing and clone that here?
  const mbClassName = mb && styles[`mb${mb}`];
  const colorClassName = color && styles[color];
  return createElement(component, { className: classNames(variantClassName, mbClassName, colorClassName, className), id, itemProp }, children);
}
