import { ComponentProps, ReactNode, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { ModalPortal, Typography, useAnimateInOutComponent, useScreenLock } from '@alltrails/core';
import { type FocusTargetOrFalse } from 'focus-trap';
import debounce from 'lodash.debounce';
import { Size } from '@alltrails/denali/types';
import IconButton from '@alltrails/denali/components/IconButton';
import PagedProgressBar from '@alltrails/denali/components/PagedProgressBar';
import ArrowSq from '@alltrails/denali/icons/ArrowSq';
import Close from '@alltrails/denali/icons/Close';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import FooterButtons from '../FooterButtons';
import styles from './styles/Modal.module.scss';

type ModalProps = {
  allowOutsideClicks?: boolean;
  ariaLabelElementId?: string;
  buttons?: Pick<ComponentProps<typeof FooterButtons>, 'fill' | 'defaultButton' | 'flatButton' | 'primaryButton'>;
  caption?: ReactNode;
  children: ReactNode;
  contentsClassName?: string;
  focusTrapOptions?: ComponentProps<typeof FocusTrap>['focusTrapOptions'];
  initialFocus?: FocusTargetOrFalse;
  isOpen: boolean;
  isPaused?: boolean;
  modalClassName?: string;
  backgroundClassName?: string;
  titleClassName?: string;
  onBackRequest?: () => void;
  onCloseComplete?: () => void;
  onCloseRequest: () => void;
  shouldHideHeader?: boolean;
  shouldHideCloseButton?: boolean;
  pageInfo?: Pick<ComponentProps<typeof PagedProgressBar>, 'index' | 'total'>;
  size?: Size<'sm' | 'md' | 'lg' | 'xl'>;
  dimensionsTheme?: 'default' | 'compact';
} & (
  | {
      headerImage?: never;
      subtitle?: ReactNode;
      title?: ReactNode;
    }
  | {
      headerImage?: ReactNode;
      subtitle?: never;
      title?: never;
    }
);

// https://www.figma.com/design/9Y1n3VoMJAcTGPe82bvySj/Denali-Components?node-id=3084-40503
const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      allowOutsideClicks = false,
      ariaLabelElementId,
      buttons,
      caption,
      children,
      contentsClassName,
      focusTrapOptions,
      headerImage,
      initialFocus,
      isOpen,
      isPaused = false,
      modalClassName,
      backgroundClassName,
      titleClassName,
      onBackRequest,
      onCloseRequest,
      onCloseComplete,
      pageInfo,
      size = 'md',
      subtitle,
      title,
      shouldHideHeader = false,
      shouldHideCloseButton = false,
      dimensionsTheme = 'default'
    },
    ref
  ): JSX.Element | null => {
    const { isAnimating, isVisible, onAnimationEnd } = useAnimateInOutComponent(isOpen, onCloseComplete);
    useScreenLock(isVisible);
    const intl = useIntl();
    const isMobile = useIsMobileSizedScreen();
    const scrollContainer = useRef<HTMLDivElement>(null);
    const headerImageContainer = useRef<HTMLDivElement>(null);
    const [amountScrolled, setAmountScrolled] = useState(0);
    const [canScrollDown, setCanScrollDown] = useState(false);
    const [canScrollUp, setCanScrollUp] = useState(false);

    const isImageModal = useMemo(() => Boolean(headerImage), [headerImage]);

    const titleElement = useMemo(() => {
      if (title) {
        return (
          <div className={classNames(styles.titleContainer, titleClassName)}>
            <Typography variant={isMobile ? 'heading300' : 'heading400'}>{title}</Typography>
            {subtitle && (
              <Typography variant="text200" color="secondary">
                {subtitle}
              </Typography>
            )}
          </div>
        );
      }
      return null;
    }, [isMobile, subtitle, title]);

    const updateScroll = useCallback(() => {
      if (scrollContainer.current) {
        const { clientHeight, scrollHeight, scrollTop } = scrollContainer.current;
        setAmountScrolled(scrollTop);
        setCanScrollDown(clientHeight + scrollTop + 1 < scrollHeight); // + 1 to deal with decimals at misc screen heights
        setCanScrollUp(scrollTop > 0);
      }
    }, []);

    useEffect(() => {
      if (isVisible) {
        updateScroll();
      }
    }, [children, isVisible, updateScroll]);

    const showFooter = Boolean(buttons || pageInfo);

    // When the image has been scrolled half out of view, we want to start making the header opaque rather than transparent.
    // We do this gradually until the entire image has been scrolled out of view, at which point the header will be completely opaque.
    const headerStyle = useMemo(() => {
      if (isImageModal && headerImageContainer.current) {
        const imageHeight = headerImageContainer.current.clientHeight;
        const halfImageHeight = imageHeight / 2;

        let opacity: number;
        if (amountScrolled >= imageHeight) {
          opacity = 100;
        } else if (amountScrolled <= halfImageHeight) {
          opacity = 0;
        } else {
          opacity = 100 * ((amountScrolled - halfImageHeight) / halfImageHeight);
        }

        return { backgroundColor: `color-mix(in srgb, currentColor ${opacity}%, transparent)` };
      }
      return undefined;
    }, [amountScrolled, isImageModal]);

    // Debounce to avoid multiple onCloseRequest calls when a user clicks the close button - once for the button, a second time for onDeactivate
    const debouncedOnClose = useMemo(() => debounce(onCloseRequest, 1000, { leading: true, trailing: false }), [onCloseRequest]);

    if (!isAnimating && !isVisible) {
      return null;
    }

    return (
      <ModalPortal>
        <div
          ref={ref}
          // The modal portal is rendered outside of the div, so it is necessary to add theming here
          data-at-theme-dimensions={dimensionsTheme}
          className={classNames(styles.background, backgroundClassName, {
            [styles.isAnimating]: isAnimating,
            [styles.isVisible]: isVisible,
            [styles.transparent]: allowOutsideClicks
          })}
          onAnimationEnd={onAnimationEnd}
          id="modalBackground" // Needed by legacy hooks and components
        >
          <FocusTrap
            active={isVisible}
            paused={isPaused}
            focusTrapOptions={{
              clickOutsideDeactivates: !allowOutsideClicks,
              escapeDeactivates: true,
              onDeactivate: debouncedOnClose,
              initialFocus,
              ...focusTrapOptions
            }}
          >
            {/* Need to stop propagation of any animationEnd event within the modal so that it doesn't trigger the onAnimationEnd of the modalBackground component */}
            <div
              className={classNames(styles.modal, styles[size], modalClassName)}
              aria-labelledby={ariaLabelElementId}
              role="dialog"
              onAnimationEnd={e => e.stopPropagation()}
            >
              {!shouldHideHeader && (
                <div
                  className={classNames(styles.header, { [styles.imageModal]: isImageModal, [styles.bottomBorder]: canScrollUp })}
                  style={headerStyle}
                >
                  {onBackRequest ? (
                    <IconButton
                      icon={{ Component: ArrowSq, orientation: 'left' }}
                      onClick={onBackRequest}
                      size="sm"
                      testId="modal-back"
                      title={intl.formatMessage({ defaultMessage: 'Go back' })}
                      variant={isImageModal ? 'elevated' : 'default'}
                    />
                  ) : (
                    <div className={styles.buttonPlaceholder} />
                  )}
                  {isMobile && titleElement}
                  {!shouldHideCloseButton && (
                    <IconButton
                      icon={{ Component: Close }}
                      onClick={debouncedOnClose}
                      size="sm"
                      testId="modal-close"
                      title={intl.formatMessage({ defaultMessage: 'Close' })}
                      variant={isImageModal ? 'elevated' : 'default'}
                      className={styles.closeButton}
                    />
                  )}
                </div>
              )}
              <div className={styles.scrollContainer} onScroll={updateScroll} ref={scrollContainer} id="modal-scroll-container">
                {isImageModal && (
                  <div className={styles.headerImageContainer} ref={headerImageContainer}>
                    {headerImage}
                  </div>
                )}
                <div className={classNames(styles.contents, contentsClassName)}>
                  {!isMobile && titleElement}
                  {children}
                </div>
              </div>
              {showFooter && (
                <div className={classNames(styles.footer, { [styles.topBorder]: canScrollDown })}>
                  {pageInfo && <PagedProgressBar className={styles.pagedProgressBar} {...pageInfo} />}
                  {buttons && <FooterButtons {...buttons} fill={buttons.fill ?? isMobile} />}
                  {caption && <div className={styles.caption}>{caption}</div>}
                </div>
              )}
            </div>
          </FocusTrap>
        </div>
      </ModalPortal>
    );
  }
);

export default Modal;
