import { AriaRole, forwardRef, ReactNode, Ref } from 'react';
import classNames from 'classnames';
import { InputBaseProps, Size } from '../../types';
import styles from './styles/styles.module.scss';

type InputFieldProps = Pick<InputBaseProps, 'disabled' | 'errorText' | 'helperText' | 'labelText'> & {
  children: ReactNode;
  className?: string;
  characterLimitText?: ReactNode;
  role?: AriaRole;
  testId?: string;
  useDivInsteadOfLabel?: boolean;
  size?: Size<'sm' | 'md'>;
};

const InputField = forwardRef(
  (
    { characterLimitText, children, className, disabled, errorText, helperText, labelText, role, testId, useDivInsteadOfLabel, size = 'md' }: InputFieldProps,
    ref: Ref<HTMLElement>
  ): JSX.Element => {
    const contents = (
      <>
        {labelText && <div className={classNames(styles.labelText, styles[size])}>{labelText}</div>}
        {children}
        {!disabled && errorText && (
          <div className={styles.errorText}>
            <span>{errorText}</span> {characterLimitText && <span> {characterLimitText} </span>}
          </div>
        )}
        {helperText && <div className={styles.helperText}>{helperText}</div>}
      </>
    );
    return useDivInsteadOfLabel ? (
      <div className={classNames(styles.container, className)} data-testid={testId} ref={ref as Ref<HTMLDivElement>} role={role}>
        {contents}
      </div>
    ) : (
      <label className={classNames(styles.container, className)} data-testid={testId} ref={ref as Ref<HTMLLabelElement>} role={role}>
        {contents}
      </label>
    );
  }
);

export default InputField;
