import React, { useState } from 'react';
import NextImage, { ImageLoader } from 'next/image';
import ShimmerWrapper from '@alltrails/shared/components/ShimmerWrapper';
import getRgbDataUrl from '../../utils/getRgbDataUrl';
import styles from './styles.module.scss';

type ImageProps = {
  id?: string;
  src: any;
  className?: string;
  loader?: ImageLoader;
  alt: string;
  title?: string;
  height?: number;
  width?: number;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  priority?: boolean;
  quality?: number;
  fill?: boolean;
  sizes?: string;
  style?: any;
  testId?: string;
  placeholder?: 'blur' | 'empty' | undefined;
  loading?: 'lazy' | 'eager';
  unoptimized?: boolean;
  isLcp?: boolean;
  layout?: string;
  itemProp?: string;
  handleLoadingState?: boolean;
};

const Image = ({
  id,
  src,
  className,
  loader,
  alt,
  title,
  height,
  width,
  onError,
  priority = false,
  quality,
  loading,
  onLoad,
  fill,
  placeholder = 'blur',
  sizes,
  style = {},
  testId,
  unoptimized,
  isLcp = false,
  layout,
  itemProp,
  handleLoadingState
}: ImageProps): JSX.Element => {
  const [ready, setReady] = useState(false);

  let baseStyles = isLcp
    ? {} // To avoid delay on displaying LCP
    : {
        opacity: ready ? 1 : handleLoadingState ? 0 : 0.2,
        transition: 'opacity .3s ease-in-out'
      };

  const ImageComponent = (
    <NextImage
      id={id}
      data-testid={testId}
      style={{ ...baseStyles, ...style }}
      className={className}
      src={src}
      height={height}
      alt={alt}
      title={title}
      placeholder={placeholder}
      blurDataURL={getRgbDataUrl(234, 241, 228)}
      width={width}
      onError={onError}
      priority={isLcp || priority}
      quality={quality}
      fill={fill}
      loading={isLcp ? 'eager' : loading}
      sizes={sizes}
      onLoad={e => {
        setReady(true);
        onLoad?.(e);
      }}
      loader={loader}
      unoptimized={unoptimized}
      layout={layout}
      itemProp={itemProp}
    />
  );

  if (handleLoadingState && !ready) {
    return <ShimmerWrapper className={styles.loading}>{ImageComponent}</ShimmerWrapper>;
  }

  return ImageComponent;
};

export default Image;
