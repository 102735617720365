'use client';

import { ComponentProps, MouseEvent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import NextLink from 'next/link';
import { useLanguageRegionCode } from '@alltrails/language';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import IconRenderer, { IconDefinition } from '../IconRenderer';
import { LinkRel, LinkTarget, Size } from '../../types';
import styles from './styles/Link.module.scss';
import getAnchorRel from './getAnchorRel';
import useIsExternalLink from './useIsExternalLink';
import isAbsoluteLink from './isAbsoluteLink';

export type LinkProps = {
  className?: string;
  href?: string;
  icon?: IconDefinition<'orientation'>;
  itemProp?: string;
  noUnderline?: boolean;
  onClick?: (e: MouseEvent) => void;
  prefetch?: ComponentProps<typeof NextLink>['prefetch'];
  rel?: LinkRel | LinkRel[];
  size?: Size<'sm' | 'md' | 'lg'>; // you can use a Link inside of block of text where we want the font size to be cascaded so don't require a size
  target?: LinkTarget;
  testId?: string;
  title?: string;
  variant?: 'primary' | 'secondary' | 'inverse';
  outsideOfMugen?: boolean;
};

// https://www.figma.com/file/KEnmNcWLwGNXOhw5iHm7n6/DNLI-17%3A-Update-link-styles?type=design&node-id=0-1&t=DSQwqHQG9EvSBRhg-0
const Link = ({
  children,
  className,
  href,
  icon,
  itemProp,
  noUnderline,
  onClick,
  prefetch = false,
  size,
  target,
  testId,
  title,
  variant = 'primary',
  rel,
  outsideOfMugen
}: PropsWithChildren<LinkProps>): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const isExternal = useIsExternalLink(href);

  const classes = classNames(className, styles.link, size && styles[size], styles[variant], {
    [styles.noUnderline]: noUnderline,
    [styles.icon]: icon
  });

  const content = icon ? (
    <>
      <IconRenderer icon={icon} defaults={{ color: 'currentColor', size }} />
      <span>{children}</span>
    </>
  ) : (
    children
  );

  if (!href) {
    return (
      <button className={classes} onClick={onClick} data-testid={testId} type="button" aria-label={title}>
        {content}
      </button>
    );
  }

  const isAbsolute = isAbsoluteLink(href);

  const linkProps = {
    className: classes,
    onClick,
    href: isAbsolute ? href : wrapUrlSafe(href, languageRegionCode),
    'data-testid': testId,
    rel: getAnchorRel(rel, target, isExternal),
    target,
    title,
    itemProp,
    prefetch
  };

  if (isExternal || outsideOfMugen) {
    // Prefetch doesn't exist on the native anchor element and causes hydration warnings.
    const { prefetch, ...nativeLinkAttributes } = linkProps;

    return <a {...nativeLinkAttributes}>{content}</a>;
  }

  return <NextLink {...linkProps}>{content}</NextLink>;
};

export default Link;
